document.addEventListener("DOMContentLoaded", () => {
    const {
        initPhoneNumber,
        prefillForm,
        showParagraphs,
        showParagraphsAlternative,
        setPropertyAddress,
        onRenewalFormSubmit,
    } = WebflowCustomCode;

    initPhoneNumber();
    prefillForm();
    showParagraphs();
    showParagraphsAlternative();
    setPropertyAddress();
    onRenewalFormSubmit();
});

const WebflowCustomCode = (function () {
    const phoneNumberElem = document.querySelector(
        '[data-name="Phone Number"]'
    );

    function onRenewalFormSubmit() {
        const form = document.querySelector('[data-name="Renewal Form"]');

        form && form.addEventListener("submit", () => {
            trackRenewal();
        });
    }

    function trackRenewal() {
        const params = getDecodedUrlParameters();
        
        window["msdynmkt"].setUser({ authId: params.id });
        window["msdynmkt"].trackEvent({
            name: "msdynmkt_webformrenewal_041318505",
            ingestionKey: "c9001fdb530b4826bcc4092af5acc56b-e7f4ef7a-8341-4731-840e-21f43eda7816-7220",
            version: "1.0.0",
            properties: {
                "bindingid": "",
                "firstname": params.first_name || "",
                "email": params.email || "",
                "lastname": params.last_name || "",
                "contactid": params.id
            }
        });
    }

    function showParagraphs() {
        const params = getParams(window.location.search);

        if (params.has_hp && params.has_hp === "true") {
            document
                .querySelectorAll(".hide_hp_paragraph")
                .forEach((paragraph) => {
                    paragraph.style.display = "block";
                });
        }
    }

    function showParagraphsAlternative() {
        const params = getParams(window.location.search);

        if (!params.warranty) return;

        if (params.warranty === "true") {
            document.querySelectorAll(".is_original").forEach((paragraph) => {
                paragraph.style.display = "none";
            });

            document
                .querySelectorAll(".is_alternative")
                .forEach((paragraph) => {
                    paragraph.style.display = "block";
                });
        } else {
            document
                .querySelectorAll(".is_alternative")
                .forEach((paragraph) => {
                    paragraph.style.display = "none";
                });

            document.querySelectorAll(".is_original").forEach((paragraph) => {
                paragraph.style.display = "block";
            });
        }
    }

    function prefillForm() {
        const params = getParams(window.location.search);

        if (params.data) {
            setTimeout(() => {
                const b64decoded = replacePhoneWithMobile(atob(params.data));
                const data = getParams(b64decoded);
                const fields = document.querySelectorAll(".text-field");

                fields.forEach((f) => {
                    if (data[f.id]) {
                        let value = data[f.id];
                        f.value = value.trim();

                        if (
                            !["email", "phone"].includes(f.id) &&
                            f.dataset.disable != "no"
                        ) {
                            f.disabled = true;
                        }
                    }
                });

                triggerValidatePhoneNumber();
            }, 500);
        }
    }

    function setPropertyAddress() {
        const params = getParams(window.location.search);
        const propertyAddressDiv = document.querySelector('div[flick-element="address"]');
        let propertyAddress = 'No address specified.';

        if (params.data && propertyAddressDiv) {
            const data = getParams(atob(params.data));

            if (data.address || data.suburb || data.state || data.zip) {
                propertyAddress = [
                    data.address,
                    data.suburb,
                    data.state,
                    data.zip
                ].filter(Boolean).join(', ');
            }

            propertyAddressDiv.innerText = propertyAddress;
        }
    }

    function replacePhoneWithMobile(queryString) {
        if (!document.querySelector("#mobile")) return queryString;

        const urlParams = new URLSearchParams(queryString);
        const phoneNumber = urlParams.get("phone");

        if (phoneNumber !== null) {
            const trimmedPhoneNumber = phoneNumber
                .trim()
                .replace(/\s+/g, "")
                .replace("+", "");
            let mobileNumber = trimmedPhoneNumber;

            if (/^(61)/.test(mobileNumber)) {
                mobileNumber = mobileNumber.replace("61", "0");
            }

            if (/^(04)/.test(mobileNumber)) {
                urlParams.set("mobile", mobileNumber);
                urlParams.delete("phone");
            }
        }
        return urlParams.toString();
    }

    function customFieldsValidationMessage() {
        const fieldsToValidate = {
            zip: "Postcode must be 4 digits.",
            mobile: 'Mobile number must start with +614 and contain 9 digits (not including the initial "0")',
            phone: 'Phone number must contain 9 digits (not including the initial "0")',
        };

        for (const field in fieldsToValidate) {
            const errorMessage = fieldsToValidate[field];

            const inputField = document.querySelector(`#${field}`);

            if (inputField) {
                inputField.addEventListener("invalid", () => {
                    if (inputField.validity.patternMismatch) {
                        inputField.setCustomValidity(errorMessage);
                    } else {
                        inputField.setCustomValidity("");
                    }
                });

                inputField.addEventListener("change", () => {
                    inputField.setCustomValidity("");
                });
            }
        }
    }

    function addIntlTelInputLibraryScript() {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.integrity =
            "sha512-hpJ6J4jGqnovQ5g1J39VtNq1/UPsaFjjR6tuCVVkKtFIx8Uy4GeixgIxHPSG72lRUYx1xg/Em+dsqxvKNwyrSg==";
        script.src =
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/intlTelInput.min.js";
        script.crossOrigin = "anonymous";
        script.referrerPolicy = "no-referrer";

        const firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(script, firstScript);
    }

    function initPhoneNumber() {
        let input = phoneNumberElem;

        if (!input) return false;

        setFormPhoneValidationSettings(input);
        createErrorDivIfNotExists(input);
        createValidDivIfNotExists(input);

        let errorMsg = document.querySelector('[data-name="error"]'),
            validMsg = document.querySelector('[data-name="valid"]');

        let errorMap = {
            gb: [
                "Invalid number",
                "Invalid country code",
                "Number is too short",
                "Number is too long",
                "Invalid number",
            ],
            de: [
                "Ungültige Nummer",
                "Ungültiger Ländercode",
                "Nummer ist zu kurz",
                "Nummer ist zu lang",
                "Ungültige Nummer",
            ],
            au: [
                "Invalid number",
                "Invalid country code",
                "Number is too short",
                "Number is too long",
                "Invalid number",
            ],
        };

        //Follow ISO 3166-1 alpha-2 codes https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements
        let dataLang = input.dataset.lang;
        let hasErrors = false;

        let iti = window.intlTelInput(input, {
            nationalMode: true,
            initialCountry: "auto",
            geoIpLookup: function (success, failure) {
                if (dataLang) {
                    success(dataLang);
                } else {
                    success("de");
                }
            },
            utilsScript:
                "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.18/js/utils.min.js",
        });

        let reset = function () {
            input.classList.remove("phone-input_error");
            errorMsg.innerHTML = "";
            errorMsg.classList.add("phone-input_element-hide");
            validMsg.classList.add("phone-input_element-hide");
        };

        input.addEventListener("blur", function () {
            reset();
            if (iti.isValidNumber() || input.value.trim() == "") {
                hasErrors = false;

                validMsg.classList.remove("phone-input_element-hide");

                if (input.value.trim() != "") {
                    input.value = iti.getNumber();
                }
            } else {
                hasErrors = true;
                input.classList.add("phone-input_error");
                let errorCode = iti.getValidationError();
                errorCode = errorCode == -99 ? 0 : errorCode;

                errorMsg.innerHTML = errorMap[dataLang][errorCode];
                errorMsg.classList.remove("phone-input_element-hide");
            }
        });

        input.addEventListener("change", reset);
        input.addEventListener("keyup", reset);

        document
            .querySelector("[data-settings=phone-validation]")
            .querySelector("input[type=submit]").onclick = function (e) {
                if (hasErrors) {
                    e.preventDefault();
                } else {
                    return null;
                }
            };
    }

    function createErrorDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_warning");
        div.classList.add("phone-input_element-hide");

        div.dataset.name = "error";
        div.setAttribute("id", "error-msg");

        return div;
    }

    function createPhoneValidDiv() {
        const div = document.createElement("div");

        div.classList.add("phone-input_valid-icon");
        div.classList.add("phone-input_element-hide");
        div.classList.add("w-embed");

        div.dataset.name = "valid";

        const svg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
        );

        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        svg.setAttribute("viewBox", "0 0 16 16");
        svg.setAttribute("fill", "none");

        const circle = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "circle"
        );

        circle.setAttribute("cx", "50%");
        circle.setAttribute("cy", "50%");
        circle.setAttribute("r", "50%");
        circle.setAttribute("fill", "#33B497");

        const path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path"
        );

        path.setAttribute(
            "d",
            "M6.92168 10.6673C6.81088 10.6673 6.70931 10.6263 6.6262 10.5341L4.78872 8.49505C4.62251 8.31061 4.62251 8.02371 4.78872 7.83927C4.95492 7.65483 5.21346 7.65483 5.37967 7.83927L6.93091 9.55044L10.6151 5.47231C10.7813 5.28787 11.0399 5.28787 11.2061 5.47231C11.3723 5.65675 11.3723 5.94365 11.2061 6.12809L7.22639 10.5341C7.13405 10.6263 7.03248 10.6673 6.92168 10.6673Z"
        );
        path.setAttribute("fill", "white");

        svg.appendChild(circle);
        svg.appendChild(path);
        div.appendChild(svg);

        return div;
    }

    function createErrorDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="error"]')) {
            phoneInput.parentNode.appendChild(createErrorDiv());
        }
    }

    function createValidDivIfNotExists(phoneInput) {
        if (!phoneInput.closest("form").querySelector('[data-name="valid"]')) {
            phoneInput.parentNode.appendChild(createPhoneValidDiv());
        }
    }

    function setFormPhoneValidationSettings(phoneInput) {
        phoneInput.closest("form").dataset.settings = "phone-validation";
    }

    function triggerValidatePhoneNumber() {
        if (phoneNumberElem) {
            phoneNumberElem.focus();
            phoneNumberElem.blur();
        }
    }

    function getDecodedUrlParameters() {
        const params = getParams(window.location.search);

        if (params.data) {
            return getParams(atob(params.data));
        }

        return null;
    }

    return {
        initPhoneNumber,
        prefillForm,
        addIntlTelInputLibraryScript,
        showParagraphs,
        showParagraphsAlternative,
        setPropertyAddress,
        onRenewalFormSubmit,
    };
})();

WebflowCustomCode.addIntlTelInputLibraryScript();
